// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

import {getFirebaseBackend} from "../../helpers/firebase_helper";
import firebase from "firebase/compat/app";

export async function removeConexao(name, data) {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    let documentSnapshot = await getFirebaseBackend()
        .getConexaoCollection()
        .doc(uid)
        .get();

    if (documentSnapshot.exists) {
        let instancias = documentSnapshot.data().instancias;
        await getFirebaseBackend()
            .getConexaoCollection()
            .doc(uid)
            .update({instancias: instancias.filter(instancia => instancia.instance.instanceName !== name)});
    }
}

const setConexao = async (name, data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    await getFirebaseBackend()
        .getConexaoCollection()
        .doc(uid)
        .update({instancias: firebase.firestore.FieldValue.arrayUnion(data)});
};
const setConexao2 = async () => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    await getFirebaseBackend()
        .getConexaoCollection()
        .doc(uid)
        .update({
            instancias: firebase.firestore.FieldValue.arrayUnion({
                id: "ade691dc-7d32-4994-8197-615dc6e64d47",
                name: "dkpfrz63w6r",
                connectionStatus: "open",
                ownerJid: "554888481019@s.whatsapp.net",
                profileName: null,
                profilePicUrl:
                    "https://pps.whatsapp.net/v/t61.24694-24/382450753_2315521235299342_2685671057828687133_n.jpg?ccb=11-4&oh=01_Q5AaIPNwo0lYrx76NDZBPVejCwzZqWtv_apL-nlZGrywVNui&oe=66D4AC95&_nc_sid=5e03e0&_nc_cat=108",
                integration: "WHATSAPP-BAILEYS",
                number: "48988481019",
                businessId: null,
                token: "97E220137A6C-4E02-8E13-9DA36194DE15",
                clientName: "evolution_v2",
                disconnectionReasonCode: null,
                disconnectionObject: null,
                disconnectionAt: null,
                createdAt: "2024-08-22T19:34:53.657Z",
                updatedAt: "2024-08-22T19:35:30.993Z",
                Chatwoot: null,
                Proxy: null,
                Rabbitmq: null,
                Sqs: null,
                Websocket: null,
                Setting: {
                    id: "cm05ombxi000114lzaoltszgp",
                    rejectCall: false,
                    msgCall: "",
                    groupsIgnore: false,
                    alwaysOnline: false,
                    readMessages: false,
                    readStatus: true,
                    syncFullHistory: true,
                    createdAt: "2024-08-22T19:34:53.666Z",
                    updatedAt: "2024-08-22T19:38:02.316Z",
                    instanceId: "ade691dc-7d32-4994-8197-615dc6e64d47",
                },
                _count: {
                    Message: 85444,
                    Contact: 2299,
                    Chat: 900,
                },
            }),
        });
};

const getPlano = async () => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    const doc = await getFirebaseBackend().getConexaoCollection().doc(uid).get();
    if (doc.exists) {
        return doc.data().plano;
    } else {
        throw new Error("No plano");
    }
};

export {setConexao, getPlano, setConexao2};
