import {Col, FormGroup, FormText, Input, Label, Spinner, UncontrolledAlert,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React, {useState} from "react";
import {camposMensagens} from "../fields";

const ArquivoInput = (props) => {
    const [, setDoc] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleChange(e) {
        try {
            const url = URL.createObjectURL(e.target.files[0]);
            props.createMensagensSet(e.target.files[0], camposMensagens.ARQUIVO);
            setLoading(true);
            setDoc(url);
            setError(false);
            setLoading(false);
        } catch (e) {
            setDoc(null);
            setError(true);
            setLoading(false);
            console.error(e);
        }
    }

    return (
        <>
            <FormGroup row>
                <Label for="document-input" sm={3}>
                    {props.t("WA Video")}
                </Label>
                <Col sm={9}>
                    <Col sm={9}>
                        <Input
                            id="document-input"
                            name="document"
                            type="file"
                            className="form-control border border-secondary"
                            onChange={handleChange}
                        />
                        <FormText>
                            O arquivo para upload deve ter no máximo 15MB.
                        </FormText>
                    </Col>
                </Col>
            </FormGroup>
            <FormGroup row>
                {error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                    >
                        Oops, Problema ao subir o documento
                    </UncontrolledAlert>
                )}
                {loading && (
                    <Spinner className="m-1" color="primary" animation="border"/>
                )}
            </FormGroup>
        </>
    );
};
ArquivoInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ArquivoInput));
