import {Col, FormGroup, FormText, Input, Label, Spinner, UncontrolledAlert,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React, {useState} from "react";
import {camposMensagens} from "../fields";
import {imageCompressionOptions} from "../../../../../../util/image";
import imageCompression from "browser-image-compression";

const ImagemInput = (props) => {
    const [image, setImage] = useState(
        props.mensagensState.mensagem[camposMensagens.IMAGEM],
    );
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleChange(e) {
        try {
            if (e.target?.files[0].type.includes("image")) {
                imageCompression(e.target.files[0], imageCompressionOptions).then(image => {
                    const url = URL.createObjectURL(e.target.files[0]);
                    setLoading(true);
                    setImage(url);
                    setError(false);
                    setLoading(false);
                    props.createMensagensSet(image, camposMensagens.IMAGEM);
                })
            } else {
                setImage(null);
                setError(true);
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setImage(null);
            setError(true);
            setLoading(false);
        }
    }

    return (
        <>
            <FormGroup row>
                <Label for="imagem-input" sm={3}>
                    {props.t("WA Image")}
                </Label>
                <Col sm={9}>
                    <Col sm={9}>
                        <Input
                            id="imagem-input"
                            name="imagem"
                            type="file"
                            accept="image/*"
                            className="form-control border border-secondary"
                            onChange={handleChange}
                            validate={{required: {value: true}}}
                        />
                        <FormText>
                            A imagem para upload deve ter no máximo 10MB.
                        </FormText>
                    </Col>
                </Col>
            </FormGroup>
            <FormGroup row>
                {image && (
                    <>
                        {/*<Button type='reset' className="btn btn-warning btn-md waves-effect waves-light"*/}
                        {/*        onClick={handleReset}>*/}
                        {/*    <i className="mdi mdi-file-remove font-size-12 align-middle me-2"></i>*/}
                        {/*</Button>*/}
                        <img
                            src={image}
                            alt="preview"
                            style={{maxWidth: 150, maxHeight: 150, margin: "auto"}}
                            align={"center"}
                            className="form-control"
                        />
                    </>
                )}
                {error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                    >
                        Oops, Problema ao subir a imagem
                    </UncontrolledAlert>
                )}
                {loading && (
                    <Spinner className="m-1" color="primary" animation="border"/>
                )}
            </FormGroup>
        </>
    );
};
ImagemInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ImagemInput));
