import {Col, FormGroup, FormText, Input, Label, Spinner, UncontrolledAlert,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React, {useState} from "react";
import {camposMensagens} from "../fields";

const AudioInput = (props) => {
    const [audio, setAudio] = useState(
        props.mensagensState.mensagem[camposMensagens.AUDIO],
    );
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                props.createMensagensSet(baseURL, camposMensagens.AUDIO);
                resolve(baseURL);
            };
        });
    };

    function handleChange(e) {
        try {
            const url = URL.createObjectURL(e.target.files[0]);
            setLoading(true);
            getBase64(e.target.files[0]).then(() => {
                setAudio(url);
                setError(false);
                setLoading(false);
                return () => URL.revokeObjectURL(url);
            });
        } catch (e) {
            setAudio(null);
            setError(true);
            setLoading(false);
            console.error(e);
        }
    }

    return (
        <>
            <FormGroup row>
                <Label for="audio" sm={3}>
                    {props.t("WA Video")}
                </Label>
                <Col sm={9}>
                    <Col sm={9}>
                        <Input
                            id="audio"
                            name="audio"
                            type="file"
                            accept="audio/*"
                            className="form-control border border-secondary"
                            onChange={handleChange}
                        />
                        <FormText>
                            O áudio para upload deve ter no máximo 10MB.
                        </FormText>
                    </Col>
                </Col>
            </FormGroup>
            <FormGroup row>
                {audio && (
                    <audio src={audio} controls>
                        <track kind="captions"/>
                    </audio>
                )}
                {error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                    >
                        Oops, Problema ao subir o video
                    </UncontrolledAlert>
                )}
                {loading && (
                    <Spinner className="m-1" color="primary" animation="border"/>
                )}
            </FormGroup>
        </>
    );
};
AudioInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(AudioInput));
