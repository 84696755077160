// Função para combinar o array antigo com novos dados
export function updateArray(oldArray, newArray) {
    const updatedArray = oldArray.map((oldItem) => {
        // Encontrar item correspondente no novo array
        const newItem = newArray.find(item => item.id === oldItem.id);

        // Se encontrar o item, sobrescreve os dados antigos
        return newItem ? {...oldItem, ...newItem} : oldItem;
    });

    // Adicionar os novos itens que não estão no array antigo
    const newItems = newArray.filter(newItem =>
        !oldArray.some(oldItem => oldItem.id === newItem.id)
    );

    return [...updatedArray, ...newItems];
}


// Função para verificar se uma string contém números
const containsNumber = (str) => /\d/.test(str);

// Função para extrair o número de uma string (ou retornar Infinity se não houver número)
const extractNumber = (str) => {
    const match = str.match(/\d+/); // Encontra o primeiro número na string
    return match ? parseInt(match[0], 10) : Infinity;
};

// Função para dividir o texto e o número no subject
const splitAlphaNumeric = (str) => {
    const match = str.match(/^([\w\s]+?)(?:\s*#(\d+))?$/); // Divide texto e número
    if (match) {
        return [match[1].trim(), parseInt(match[2] || "0", 10)]; // Retorna texto e número
    } else if (str?.includes('#')) {
        let split = str.split('#');
        return [split[0].trim(), parseInt(split[1] || "0", 10)]; // Retorna texto e número
    }
    return [str, 0]; // Caso não tenha número
};

// Função para ordenar o array
export const ordemAlfabetica = (a, b) => {
    const [textA, numA] = splitAlphaNumeric(a.subject);
    const [textB, numB] = splitAlphaNumeric(b.subject);

    // Ordena primeiro por texto alfabeticamente, depois por número
    return textA.localeCompare(textB) || numA - numB;
}


