import {Col, FormGroup, FormText, Input, Label, Spinner, UncontrolledAlert,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as MensagensActions from "../../../../../../store/mensagens/actions";
import {connect} from "react-redux";
import React, {useState} from "react";
import {camposMensagens} from "../fields";

const VideoInput = (props) => {
    const [video, setVideo] = useState(
        props.mensagensState.mensagem[camposMensagens.VIDEO],
    );
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleChange(e) {
        try {
            if (e.target?.files[0].type.includes("video")) {
                const url = URL.createObjectURL(e.target.files[0]);
                props.createMensagensSet(e.target.files[0], camposMensagens.VIDEO);
                setLoading(true);
                setVideo(url);
                setError(false);
                setLoading(false);
            } else {
                setVideo(null);
                setError(true);
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setVideo(null);
            setError(true);
            setLoading(false);
        }
    }

    return (
        <>
            <FormGroup row>
                <Label for="video-input" sm={3}>
                    {props.t("WA Video")}
                </Label>
                <Col sm={9}>
                    <Col sm={9}>
                        <Input
                            id="video-input"
                            name="video"
                            type="file"
                            accept="video/*"
                            className="form-control border border-secondary"
                            onChange={handleChange}
                            validate={{required: {value: true}}}
                        />
                        <FormText>
                            O vídeo para upload deve ter no máximo 15MB.
                        </FormText>
                    </Col>
                </Col>
            </FormGroup>
            <FormGroup row>
                {video && (
                    <video width="100" height="100" controls>
                        <track kind="captions" {...props} />
                        <source src={video}/>
                    </video>
                )}
                {error && (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                    >
                        Oops, Problema ao subir o video
                    </UncontrolledAlert>
                )}
                {loading && (
                    <Spinner className="m-1" color="primary" animation="border"/>
                )}
            </FormGroup>
        </>
    );
};
VideoInput.propTypes = {
    t: PropTypes.any,
    createMensagensSet: PropTypes.func,
};
const mapStatetoProps = (state) => ({mensagensState: state.mensagens});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(MensagensActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(VideoInput));
