// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import {getFirebaseBackend} from "../../helpers/firebase_helper";
import firebase from "firebase/compat/app";

const createMensagem = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid
    const db = getFirebaseBackend().getMensagensCollection();
    const docRef = db.doc(uid);
    try {
        // Check if document exists
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
            // Document exists: update the 'mensagens' array
            return await docRef.update({
                mensagens: firebase.firestore.FieldValue.arrayUnion(data),
            });
        } else {
            // Document does not exist: create it with 'mensagens' array
            return await docRef.set({
                mensagens: [data], // Initialize with 'mensagens' array containing the data
            });
        }

        console.log("Document updated or created successfully.");
    } catch (error) {
        console.error("Error updating or creating document:", error);
    }
    // return await getFirebaseBackend().getMensagensCollection().doc(uid).update({mensagens: firebase.firestore.FieldValue.arrayRemove(data)})
};

const removeMensagem = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid
    return await getFirebaseBackend().getMensagensCollection().doc(uid).update({mensagens: firebase.firestore.FieldValue.arrayRemove(data)})
};


export {createMensagem, removeMensagem};
