import React, {useState} from "react";
import {CLOSE, CONNECTING, getStatus} from "../../../api/evolution/status";
import {Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardText, CardTitle, Col,} from "reactstrap";
import disconnected from "../../../assets/images/conexoes/no-connection.png";
import {getPhone} from "../../../util/phone";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as ConexoesActions from "../../../store/conexoes/actions";
import {connect} from "react-redux";
import * as instanceAPI from "../../../api/evolution/instance";

const CardConexao = (props) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const {value} = props;
    const handleDisconnect = () => {
        setLoadingButton(true);
        props.logoutConexao(value);
    };
    const handleDelete = () => {
        setLoadingButton(true);
        props.deleteConexao(value);
    };
    const handleConnect = () => {
        setLoadingButton(true);
        instanceAPI.deleteLogoutInstance(
            value.name
        ).then(() => {
            props.getConexao(value);
        }).catch(() => props.getConexao(value))
    };
    const buttonDisconnect = () => {
        if (!loadingButton) {
            return (
                <Button
                    className="btn btn-danger waves-effect waves-light"
                    onClick={handleDisconnect}
                >
                    <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Disconnect")}
                </Button>
            );
        }
        if (loadingButton) {
            return (
                <Button className="btn btn-dark waves-effect waves-light">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Disconnect")}
                </Button>
            );
        }
    };

    const buttonDelete = () => {
        if (!loadingButton) {
            return (
                <Button
                    className="btn btn-danger waves-effect waves-light"
                    onClick={handleDelete}
                >
                    <i className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Delete")}
                </Button>
            );
        }
        if (loadingButton) {
            return (
                <Button className="btn btn-dark waves-effect waves-light">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Delete")}
                </Button>
            );
        }
    };

    const buttonConnect = () => {
        if (!loadingButton) {
            return (
                <Button
                    className="btn btn-success waves-effect waves-light"
                    onClick={handleConnect}
                >
                    <i className="mdi mdi-lan-connect font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Connect")}
                </Button>
            );
        }
        if (loadingButton) {
            return (
                <Button className="btn btn-dark waves-effect waves-light">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                    {props.t("Connect")}
                </Button>
            );
        }
    };

    if (
        value.connectionStatus === CONNECTING ||
        value.connectionStatus === CLOSE
    ) {
        return (
            <Col lg={3} key={value.token}>
                <Card className="text-center">
                    <CardHeader>
                        <CardTitle className="h4 mt-0">{props.t("Connection")}</CardTitle>
                        <CardSubtitle className="h6 font-14 text-muted">
                            {value.name}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <CardText>
                            {" "}
                            <img
                                className="img-thumbnail rounded-circle avatar-sm"
                                alt={value.name}
                                src={disconnected}
                            />
                        </CardText>
                        <CardText>
                            <small>Nome Perfil: {props.t("Undefined")}</small>
                            <br/>
                            <small>Status: {getStatus(value.connectionStatus)}</small>
                            <br/>
                            <small>Número: {props.t("Undefined")}</small>
                            <br/>
                            <small>Quantidade de Grupos: 4</small>
                        </CardText>
                    </CardBody>
                    <CardFooter>{buttonConnect()}</CardFooter>
                    <CardFooter>{buttonDelete()}</CardFooter>
                </Card>
            </Col>
        );
    }
    return (
        <Col lg={3} key={value.token}>
            <Card className="text-center">
                <CardHeader>
                    <CardTitle className="h4 mt-0">{props.t("Connection")}</CardTitle>
                    <CardSubtitle className="h6 font-14 text-muted">
                        {value.name}
                    </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <CardText>
                        {" "}
                        <img
                            className="img-thumbnail rounded-circle avatar-sm"
                            alt={value.name}
                            src={value.profilePicUrl}
                        />
                    </CardText>
                    <CardText>
                        <small>Nome Perfil: {value.profileName}</small>
                        <br/>
                        <small>Status: {getStatus(value.connectionStatus)}</small>
                        <br/>
                        <small>Número: {getPhone(value.ownerJid)}</small>
                        <br/>
                        <small>Quantidade de Grupos: 4</small>
                    </CardText>
                </CardBody>
                <CardFooter>{buttonDisconnect()}</CardFooter>
                <CardFooter>{buttonDelete()}</CardFooter>
            </Card>
        </Col>
    );
};

CardConexao.propTypes = {
    t: PropTypes.any,
    value: PropTypes.any,
    logoutConexao: PropTypes.func,
    getConexao: PropTypes.func,
    deleteConexao: PropTypes.func,
};

const mapStatetoProps = (state) => ({conexoes: state.conexoes});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(ConexoesActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CardConexao));
